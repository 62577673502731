<template>
  <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="companySubSer-container">
    <div class="title-main">
      <p><span @click="() => { $router.push('/customer/company-shop-list') }">店铺管理</span> / <span @click="() => { $router.push(`/customer/company-shop-list/subaccount-list?id=${$route.query.shopid}&shopName=${$route.query.shopName}&companyId=${$route.query.companyId}&categoryName=${$route.query.categoryName ? $route.query.categoryName : ''}&platformName=${$route.query.platformName}&customerName=${$route.query.customerName}&startTime=${$route.query.startTime}&endTime=${$route.query.endTime}&shopLogo=${$route.query.shopLogo}&fileUrl=${$route.query.fileUrl}&isAssign=${$route.query.isAssign}`) }">子账号管理</span> / <span class="normal">客服分配情况</span></p>
      <div>
        <el-button class="common-screen-btn" type="primary" @click="assignService()">新建指派客服</el-button>
      </div>
    </div>
    <div class="screen-main">
      <span>订单编号:</span>
      <el-select class="screen-select" v-model="params.orderId" placeholder="请选择" @change="commonFun">
        <el-option v-for="item in shopOrder" :key="item.id" :label="item.orderNo + ' | ' + item.startTime + '-' + item.endTime" :value="item.id"></el-option>
      </el-select>
    </div>
    <div class="companySubSer-detail">
      <div>
        <div class="detail-title">
          子账号名称: {{ $route.query.subaccountName }}
        </div>
        <span class="detail-item">所属店铺: {{ $route.query.shopName }}</span>
      </div>
    </div>
    <div class="table-main">
      <el-table class="common-table" :data="tableData">
        <el-table-column
          prop="nick_name"
          label="客服名称"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column
          prop="company_name"
          label="公司名称"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column
          prop="start_time"
          label="开始时间"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column prop="end_time" label="结束时间"></el-table-column>
        <el-table-column label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="() => assignService(scope.row)"
              >编辑指派</el-button
            >&nbsp;&nbsp;&nbsp;
            <el-popconfirm
              placement="top"
              title="确定删除吗？"
              @confirm="() => deleteDistribution(scope.row.id)"
            >
              <el-button type="text" size="small" slot="reference"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      v-dialogDrag
      title="指派客服"
      :append-to-body="true"
      :visible.sync="assignVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="assignForm"
          :rules="assignRules"
          ref="assignForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="服务时间" prop="datePicker">
            <el-date-picker
              class="dialog-input"
              v-model="assignForm.datePicker"
              type="daterange"
              range-separator="~"
              :clearable="false"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="指派客服" prop="adminId">
            <el-select
              class="dialog-input"
              v-model="assignForm.adminId"
              filterable
              placeholder="选择客服"
            >
              <el-option
                v-for="item in serviceList"
                :key="item.id"
                :label="item.nickName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="assignVisible = false"
          >取 消</el-button
        >
        <el-button
          :loading="btnLoading"
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              submitAssign('assignForm');
            }
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  distributionlist,
  addEditSubaccount,
  assignService,
  deleteDistribution
} from "../../service/customer.js";
import { getRoleByType } from "../../service/common.js";
import { shopOrderList } from "../../service/manage.js"
import { tableColumn, DateTransform, Config } from "../../utils/index.js";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      loading: false,
      tableColumn,
      // BreadcrumbData: [
      //   { title: "首页", isLink: true, url: "/index" },
      //   { title: "营销", isLink: false },
      //   { title: "运营", isLink: false },
      //   { title: "店铺管理", isLink: true, url: "/customer/company-shop-list" },
      //   {
      //     title: "子账号管理",
      //     isLink: true,
      //     url: `/customer/company-shop-list/subaccount-list?id=${this.$route.query.shopid}&shopName=${this.$route.query.shopName}&companyId=${this.$route.query.companyId}&categoryName=${this.$route.query.categoryName}&platformName=${this.$route.query.platformName}&customerName=${this.$route.query.customerName}&startTime=${this.$route.query.startTime}&endTime=${this.$route.query.endTime}&shopLogo=${this.$route.query.shopLogo}&fileUrl=${this.$route.query.fileUrl}`
      //   },
      //   { title: "客服分配情况", isLink: false }
      // ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      shopid: this.$route.query.shopid, // 店铺列表传值
      tableRowId: "", // 客户id用于编辑/查看
      centerDialogVisible: false, // 新增修改弹窗
      assignVisible: false, // 指派分公司弹窗
      comPeopleList: [],
      assignForm: {
        id: this.$route.query.subId
      }, // 指派分公司form表单
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company, // 所属公司下拉
      companyId: this.$sto.get(Config.constants.userInfo).company_id,
      assignRules: {
        adminId: [{ required: true, message: "请选择客服", trigger: "change" }],
        datePicker: [
          { required: true, message: "请选择服务时间", trigger: "change" }
        ]
      },
      serviceList: [], // 获取客服下拉
      params: {
        pageNum: 1,
        pageSize: 10,
        id: this.$route.query.subId,
        orderId: ''
      },
      firstOrderId: '',
      ruleFormAdd: {
        //
        shopId: this.$route.query.shopid
      },
      rules: {
        subaccountName: [
          { required: true, message: "请填写子账号名称", trigger: "change" }
        ],
        subaccountPassword: [
          { required: true, message: "请填写子账号密码", trigger: "change" }
        ]
      },
      drawer: false, // 新增员工弹窗
      roleSelect: "",
      tableData: [],
      pickerOptions: {},
      shopOrder: []
    };
  },
  computed: {
    ...mapState(["btnLoading"])
  },
  created() {
    // if (this.rolesCompany && this.rolesCompany.length == 1) {
    //   this.params.companyId = this.rolesCompany[0].id;
    // }
    this.getShopOrder(this.shopid)
    // this.distributionlist();
    this.getRoleByType(4);
    this.getRoleByType(2);
    
  },
  methods: {
    async getShopOrder(id){ //店铺关联订单列表
      const { data } = await shopOrderList({shopId: id})
      this.params.orderId = data[0].id
      this.firstOrderId = data[0].id
      this.shopOrder = data
      this.distributionlist();
    },
    async deleteDistribution(id) {
      //删除
      await deleteDistribution({}, id);
      this.$message.success("删除成功");
      this.distributionlist();
    },
    downLoad(url) {
      // 下载交接文档
      if (url) {
        window.location.href = url;
      }
    },
    submitAssign(formName) {
      // 指派提交
      this.$vuex.commit("btnLoading", true);
      let assignForm = { ...this.assignForm };
      if (assignForm.datePicker && assignForm.datePicker.length) {
        assignForm.startTime = DateTransform(assignForm.datePicker[0]);
        assignForm.endTime = DateTransform(assignForm.datePicker[1]);
      }
      if ( this.params.orderId ) {
        assignForm.orderId = this.params.orderId
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await assignService(assignForm);
          this.$message.success("指派成功");
          this.distributionlist();
          this.assignVisible = false;
          this.$vuex.commit("btnLoading", false);
        } else {
          this.$vuex.commit("btnLoading", false);
          return false;
        }
      });
    },
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.distributionlist();
    },
    handleSelectionChange(row) {
      console.log(row);
    },
    resetBtn() {
      this.params = {
        pageNum: 1,
        pageSize: 10,
        id: this.$route.query.subId,
        orderId: this.firstOrderId
      };
      this.distributionlist();
    },
    async getRoleByType(id) {
      // 获取客服下拉
      let resData = (await getRoleByType({ companyId: this.companyId }, id))
        .data;
      if (id == 2) {
        this.comPeopleList = resData;
      } else if (id == 4) {
        this.serviceList = resData;
      }
    },
    submitForm(formName) {
      // 新增修改提交
      let tableRowId = this.tableRowId;
      let ruleFormAdd = { ...this.ruleFormAdd };
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (tableRowId) {
            ruleFormAdd.id = tableRowId;
          }
          await addEditSubaccount(ruleFormAdd);
          if (tableRowId) {
            this.$message.success("修改成功");
          } else {
            this.$message.success("添加成功");
          }
          this.distributionlist();
          this.centerDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.distributionlist();
    },
    async distributionlist() {
      // 子账号列表
      this.loading = true;
      let params = { ...this.params };
      if (params.datePicker && params.datePicker.length) {
        params.startTime = DateTransform(params.datePicker[0]);
        params.endTime = DateTransform(params.datePicker[1]);
      }
      let resData = (await distributionlist({ ...params })).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.records;
      this.loading = false;
    },
    assignService(row) {
      if (row) {
        this.assignForm = {
          id: row.id,
          subId: this.$route.query.subId,
          // adminId: row.admin_id,
          adminId: "",
          datePicker:
            row.start_time && row.end_time
              ? [new Date(row.start_time), new Date(row.end_time)]
              : []
        };
      } else {
        this.assignForm = {
          subId: this.$route.query.subId
        };
        let shopOrderItem = this.shopOrder.filter((item) => { return item.id == this.params.orderId })[0]
        this.pickerOptions = { // 指派的禁用时间范围
          disabledDate: (time) => {
            return (
              time.getTime() <
                new Date(shopOrderItem.startTime).getTime() -
                  24 * 60 * 60 * 1000 ||
              time.getTime() > new Date(shopOrderItem.endTime).getTime()
            );
          }
        }
      }
      if (this.$refs["assignForm"]) {
        this.$refs["assignForm"].resetFields();
      }
      this.assignVisible = true;
    }
  }
};
</script>
<style lang="less" scoped>
.companySubSer-container {
  text-align: left;
  .common-table {
    margin: 0;
  }
  .table-main {
    padding: 0 32px 32px;
  }
  .screen-main {
    height: 96px;
    box-sizing: border-box;
    border-bottom: 16px solid #eee;
    display: flex;
    align-items: center;
    padding: 0 32px;
    .screen-select {
      margin-left: 16px;
      width: 350px;
    }
  }
  .title-main {
    display: flex;
    align-items: center;
    padding: 0 32px;
    box-sizing: border-box;
    height: 72px;
    border-bottom: 1px solid #eee;
    justify-content: space-between;
    p {
      font-size: 20px;
      color: #666;
      span {
        font-size: 20px;
        color: #666;
        cursor: pointer;
      }
      .normal {
        font-size: 24px;
        color: #101010;
        cursor: auto;
      }
    }
  }
  .iconPeople {
    width: 80px;
    height: 80px;
    color: #fff;
    background: #87d068;
    border-radius: 50%;
    font-size: 70px;
    line-height: 80px;
    text-align: center;
    margin-right: 16px;
  }
  .companySubSer-detail {
    display: flex;
    padding: 16px 32px 16px;
    align-items: center;
    color: #000000a6;
    .detail-img {
      width: 82px;
      height: 82px;
      margin-right: 20px;
    }
    .detail-title {
      margin-bottom: 16px;
      font-size: 20px;
    }
    .detail-item {
      margin-right: 16px;
    }
  }
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-input {
  width: 320px !important;
}
</style>
